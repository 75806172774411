<template>
    <div class="absolute left-0 top-0 h-full w-full">
        <div
            v-if="item.payment_type == 1 && item.is_locked == true"
            class="absolute left-1/2 top-0 flex min-h-[90%] w-full -translate-x-1/2 transform cursor-pointer flex-col items-center justify-center">
            <p
                class="mx-auto mb-[14px] line-clamp-2 block h-[32px] w-[90%] text-[12px] tracking-[.42px] text-white sm:mb-[10px] sm:max-w-[200px] lg:mb-[18px] lg:text-[14px]"></p>
            <font-awesome-icon
                @click="openSubscriptionForm(item, $event, isLoggedIn)"
                class="mx-auto mb-[14px] block h-[38px] w-[34px] text-white lg:mb-[28px] lg:h-[50px] lg:w-[43px]"
                :icon="['fas', 'lock']" />
            <button
                @click="openSubscriptionForm(item, $event, isLoggedIn)"
                :title="'Subscribe'"
                type="button"
                class="subscribe-button mx-auto block w-[90%] rounded-[6px] bg-mintgreen px-[11px] py-[7px] text-[12px] font-medium leading-5 text-black hover:bg-hovermintgreen sm:max-w-[205px] xl:w-[90%] xl:px-[16px] xl:py-[8px] xl:text-[14px] xl:tracking-[.42px]">
                <template v-if="item.guest_allow_timeout"> Sign Up Free </template>
                <template v-else>
                    SUBSCRIBE <br class="hidden" />
                    TO VIEW
                </template>
            </button>
        </div>
        <div
            v-if="item.payment_type == 2 && item.is_locked == true"
            class="unlock-button absolute left-1/2 flex min-h-[90%] w-full -translate-x-1/2 transform cursor-pointer flex-col items-center justify-center"
            type="button">
            <font-awesome-icon
                @click="handleClick($event, isLoggedIn)"
                class="mx-auto mb-[14px] block h-[38px] w-[34px] text-white lg:mb-[18px] lg:h-[50px] lg:w-[43px]"
                :icon="['fas', 'lock']" />
            <p
                @click="handleClick($event, isLoggedIn)"
                class="mx-auto mb-[14px] line-clamp-2 min-h-[32px] w-[90%] text-center text-[12px] tracking-[.42px] text-white sm:mb-[10px] sm:max-w-[200px] lg:mb-[18px] lg:text-[14px]">
                {{ item.title }}
            </p>
            <button
                @click="handleClick($event, isLoggedIn)"
                :title="'Subscribe'"
                type="button"
                class="mx-auto block w-[90%] rounded-[6px] bg-mintgreen px-[11px] py-[7px] text-[12px] font-medium leading-[20px] text-black hover:bg-hovermintgreen xl:max-w-[196px] xl:px-[10px] xl:py-[8px] xl:text-[14px] xl:leading-[20px] xl:tracking-[.42px]">
                UNLOCK FOR <br class="hidden" />
                ${{ item.price }}
            </button>
        </div>
    </div>

    <!--PPV form-->
    <PPVForm
        :show="showPPVForm"
        :post="item"
        @onPurchasePost="onPurchasePost"
        @closeModal="closePPVForm" />

    <PopupDialog
        :show="showPPVSuccess"
        :hasButtons="false"
        :closeable="true"
        :maxWidth="'md'"
        @closeModal="closePPVSuccess">
        <template #bodyContainer>
            <PPVSuccess :creator="props.profile" :post="item" @closeModal="closePPVSuccess" />
        </template>
    </PopupDialog>

    <!--Subscription PopupDialog-->
    <SubscriptionForm
        v-if="isLoggedIn"
        :show="showSubscriptionForm"
        :creator="currentCreator"
        :authUserHasCardOnFile="props?.auth?.user.has_card_on_file"
        @onSubscribe="onSubscribe"
        @closeModal="closeSubscriptionForm" />

    <MarketingFlyout
        :conversion_user_id="conversion_user_id"
        :show="showMarketingFlyout"
        @close="showMarketingFlyout = false"
        :hideScroll="false" />
</template>
<script setup>
    import { ref } from 'vue'
    import { usePage } from '@inertiajs/vue3'
    import { router } from '@inertiajs/vue3'
    import MarketingFlyout from '@/Components/scene/MarketingFly.vue'
    import PopupDialog from '@/Components/ui-components/PopupDialog.vue'
    import PPVForm from '@/Pages/Profile/Partials/PPVForm.vue'
    import PPVSuccess from '@/Pages/Profile/Partials/PPVSuccess.vue'
    import SubscriptionForm from '@/Pages/Profile/Partials/SubscriptionForm.vue'

    const props = defineProps(['item', 'profile'])
    const { props: p } = usePage()
    const emit = defineEmits(['unlockButton'])

    const showPPVToast = ref(false)
    const currentCreator = ref({})
    const closeButton = () => {
        showPPVToast.value = false
    }

    const isLoggedIn = ref(p.auth && p.auth.user && p.auth.user.name !== null)

    // If your component accepts props, you can destructure them like this:
    // const props = defineProps(['prop1', 'prop2']);
    const showPPVForm = ref(false)
    const showPPVSuccess = ref(false)
    const showSubscriptionForm = ref(false)

    const conversion_user_id = ref(null)

    function openPPVForm() {
        showPPVForm.value = true
    }

    function unlockButton() {
        emit('unlockButton')
    }

    function openPPVSuccess() {
        showPPVSuccess.value = true
    }

    function closePPVForm() {
        showPPVForm.value = false
    }

    function closePPVSuccess() {
        showPPVSuccess.value = false
    }

    function onPurchasePost() {
        closePPVForm()
        showPPVToast.value = true
        //router.reload()
        unlockButton()
    }

    function openSubscriptionForm(post, event, isLoggedIn) {
        if (event) {
            event.stopPropagation()
        }
        if (!isLoggedIn) {
            conversion_user_id.value = post.guest_allow_timeout ? post.creator.id : null
            openMarketingFlyout()
        } else {
            currentCreator.value = props.profile || post.creator
            showSubscriptionForm.value = true
        }
    }

    function closeSubscriptionForm() {
        showSubscriptionForm.value = false
    }

    function onSubscribe() {
        closeSubscriptionForm()
        router.reload()
    }

    const handleClick = (event, isLoggedIn) => {
        event.stopPropagation()
        if (!isLoggedIn) {
            openMarketingFlyout()
        } else if (props.item.payment_type == 2 && props.item.is_locked == true) {
            openPPVForm()
        }
    }

    const showMarketingFlyout = ref(false)
    const openMarketingFlyout = () => {
        showMarketingFlyout.value = true
    }

    const closeMarketingFlyout = () => {
        showMarketingFlyout.value = false
    }
</script>
<style scoped>
    @media screen and (max-width: 640px) {
        .explore-page .unlock-button br {
            display: block;
        }
    }
</style>
